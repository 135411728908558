<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      icon="mdi-clipboard-text"
      title="待确认项目"
      class="px-5 py-3 mt-12"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th
              width="80"
            >
              序号
            </th>
            <th>项目名称</th>
            <th>填报用户</th>
            <th>所属列表</th>
            <th>填报时间</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(pending, index) in pendingList"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <span
                class="indigo--text projectNameLink"
                @click="seeProject(pending.formId, pending.id)"
              >
                {{ pending.name }}
              </span>
            </td>
            <td>
              {{ pending.nickname }}
            </td>
            <td>
              {{ pending.data.formName }}
            </td>
            <td>{{ pending.createdAt | dateformat }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="seeProject(pending.formId, pending.id)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                审核
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="error"
                @click="deleteProject(index,pending.id)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="nowPage"
        :length="pageNum"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      pendingList: [],
      nowPage: 1,
      pageNum: 0,
      formDatas: [],
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          this.getPending()
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      await this.formData()
    },
    async mounted () {
      await this.getPending()
    },
    methods: {
      async getPending () {
        const searchParame = this.$route.query
        searchParame.size = 20
        searchParame.page = this.$route.query.page ? this.$route.query.page : 0
        await this.$axios.get('/reports/audit2', {
          params: searchParame,
        })
          .then(res => {
            this.pendingList = res.data.data.content
            this.pageNum = res.data.data.totalPages
            console.log(this.formDatas, res.data.data.content)

            res.data.data.content.forEach(async val => {
              this.formDatas.forEach(valm => {
                if (valm.id === val.formId) {
                  this.$set(val.data, 'formName', valm.name)
                }
              })
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      async formData (formId) {
        const resp = await this.$axios.get('/forms')
        console.log(resp)
        this.formDatas = resp.data.data
      },
      seeProject (formId, id) {
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: formId,
            id: id,
          },
        })
      },
      deleteProject (index, id) { // 删除项目
        this.$swal({
          title: '你确定要删除"' + this.pendingList[index].name + '"项目吗？',
          text: '项目删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.delete(id)
          }
        })
      },
      delete (id) {
        this.$axios.delete(`/reports/${id}`)
          .then((response) => {
            this.getPending()
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },

      pageChange (value) { // 换页按钮
        console.log(value)

        this.nowPage = value
        this.page = value - 1
        const searchParameObj = { ...this.$route.query, ...{ page: this.page, size: 20 } }
        console.log(searchParameObj)

        this.$router.push({
          path: '/admin/pending-list',
          query: searchParameObj,
        })
      },
    },
  }
</script>
<style scoped>
  .projectNameLink {
    cursor: pointer;
  }
</style>
